// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1717744691463.2021";
}
// REMIX HMR END

import Search from "~/components/search";
import { SEARCH_PARTY_NAME, SEARCH_SINGLETON_ROOM_ID } from "~/utils";
export const meta = () => {
  return [{
    title: "Braggoscope search"
  }, {
    name: "description",
    content: "Find episodes of BBC In Our Time"
  }];
};
export default function Index() {
  return <div className="mx-auto flex flex-col justify-start items-start gap-6 w-full max-w-md">
      <h1 className="text-3xl font-semibold">Braggoscope search</h1>
      <Search party={SEARCH_PARTY_NAME} room={SEARCH_SINGLETON_ROOM_ID} />
    </div>;
}
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;