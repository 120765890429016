// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/search-results.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/search-results.tsx");
  import.meta.hot.lastModified = "1717744691463.2021";
}
// REMIX HMR END

import PartySocket from "partysocket";
import { suspend } from "suspend-react";
export default function SearchResults(props) {
  const episodes = suspend(async () => {
    const res = await PartySocket.fetch({
      host: window.location.host,
      party: props.party,
      room: props.room
    }, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: props.query
      })
    });
    if (!res.ok) {
      throw new Error(await res.text());
    }
    const {
      episodes
    } = await res.json();
    return episodes;
  }, [props.query]);
  if (!episodes.length) {
    return null;
  }
  return <div className="flex flex-col justify-start items-start gap-1">
      <div className="font-semibold">Episodes</div>
      <ul className="flex flex-col justify-start items-start gap-2">
        {episodes.map(episode => {
        return <li key={episode.id}>
              <a className="text-blue-500 hover:text-blue-700" href={`https://www.braggoscope.com${episode.permalink}`}>
                {episode.title}
              </a>{" "}
              <span className="text-gray-400 text-xs">
                {episode.published}. Score: {episode.score}
              </span>
            </li>;
      })}
      </ul>
    </div>;
}
_c = SearchResults;
var _c;
$RefreshReg$(_c, "SearchResults");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;